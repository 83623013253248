import { Fancybox } from "../resources/plugins/@fancyapps/ui";

// Подключение свайпера
const prodSlider = new Swiper('#hero__slider', {
  slidesPerView: 'auto',
  spaceBetween: 24,
  navigation: {
    nextEl: '.hero__sliderNav .js--swiperButtonNext',
    prevEl: '.hero__sliderNav .js--swiperButtonPrev',
  },
  pagination: {
    el: '.hero__sliderNav .js--swiperPagination',
    clickable: true,
  },
});

// Подключение табов
tabs('stations');
tabs('contacts');
tabsAlternative('products');

// Fancybox
Fancybox.bind('[data-fancybox="news"]', {
  hideScrollbar: false,
});

// Filter
const filterBtn = document.querySelector('.js--filterBtn');
const filter = document.querySelector('.js--filter');

if (filterBtn) {
  filterBtn.addEventListener('click', () =>	{
    filter.classList.toggle('products__left--active');
  });
}

// Фиксированная шапка
const header = document.querySelector(".header");
const headerBottom = document.querySelector(".header__bottom");

// Получаем начальное положение шапки
const sticky = header.offsetTop;

// Добавляем событие прокрутки
window.addEventListener("scroll", function() {
  // Проверяем, достигла ли прокрутка начального положения шапки
  if (window.pageYOffset > sticky) {
    // Добавляем класс "fixed", чтобы сделать шапку фиксированной
    header.classList.add("header--fixed");
    headerBottom.classList.add("header__bottom--fixed");
  } else {
    // Удаляем класс "fixed", чтобы вернуть шапке обычное положение
    header.classList.remove("header--fixed");
    headerBottom.classList.remove("header__bottom--fixed");
  }
});

// Прикрепить файл
const jsFile = document.querySelector('.js--file input');
if (jsFile) {
  jsFile.addEventListener('change', function() {
    var fileName = this.value;
    fileName = fileName.replace(/\\/g, '/').split('/').pop();
    this.nextElementSibling.textContent = fileName;
  });
}

// Accordion
toggleAccordion('vacancies');

const contactsAccordions = document.querySelectorAll('[data-accordion]');
contactsAccordions.forEach((el, ind) => {
  toggleAccordion(`contacts-${ind}`);
})
